@font-face{
    font-family: 'Helvetica Neue LT Pro 45 Light';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 65 Medium';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 75 Bold';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 55 Roman';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
  }
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


@media (max-height:800px) {
  .SIL_Logo{
    margin-top: 500px;
   }
}

@media (max-height:1200px) {
  .SIL_Logo{
    margin-top: 200px;
   }
}

@media (max-height:700px) {
  .SIL_Logo{
    margin-top: 400px;
   }
}

@media (max-height:642px) {
  .SIL_Logo{
    margin-top: 400px;
   }
}

@media (max-height:545px) {
  .SIL_Logo{
    margin-top: 450px;
   }
}
 

.form-root{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#lightbox-intro-form-inner-wrapper {
  width: 90vw;
  max-width: 420px;
  border-radius: 0px;
  padding: 20px;
  box-sizing: border-box;
  padding: 20px 20px 120px;

  label.mktoLabel.mktoHasWidth {
    display: block !important;
    margin-top: 7px;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: normal;
    color: #fff;
  }

  label#LblMarketing_Opt_In__c {
    display: none !important;
  }

  #LblmktoCheckbox_1257_0 {
    width: 50vw;
    max-width: 700px;
    color: #fff;

    span {
      color: #fff !important;
    }
    a{
      font-weight: bold;
      color: #F0B323 !important;
    }
  }

  input#LblmktoCheckbox_1257_0 {
    color: #fff;
  }
}

.Experience-the-Futur {
    margin-top: 2rem;
    font-family: 'Helvetica Neue LT Pro 65 Medium';
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; 
  }

  .Fill-out-the-form-be {
    max-width: 789px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .form-input{
    width: 380px;
    height: 50px;
    border-radius: 5px;
    padding-left: 10px;
    background-color: #f7f7f7;
    font-family: 'Open Sans', sans-serif;
    border: none;
  }

  .form-select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../assets/images/introForm/icons-arrow-down.svg');
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 96%;
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif;
  }

  .form-label{
    margin-top:7px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .form-checkbox{
    width: 20px;
    height: 20px;
    margin: 18px 12px 0 14px;
    border-radius: 5px;
    background-color: #f7f7f7;
  }

  .form-checkbox-label{
    max-width: 348px;
    margin: 16px 0 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .form-submit{
    width: 267px;
    height: 49px;
    padding: 13px 102px 14px 103px;
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.24);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .form-submit:hover{
    transition: 0.2s;
    background-color: #0086d9;
  }

  .button-div{
      display: flex;
      justify-content: center;
      padding-top: 20px;
  }

  @media (max-width:576px) {
    .form-input{
        width: 300px;
    } 
    .form-checkbox-label{
        max-width: 267px; 
    }
    .form-root{
        margin-top: 200px;
    }
  }



 