.sidebar {
    min-width: 325px;
    max-width: 325px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    margin-left: -325px;
    transition: all 0.5s;
    position: absolute;
    z-index: 30;
  }

  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  .sidebar-content{
    height: 89%;
    width: 82%;
      margin-top: 80px;
      overflow-y: auto;
  }

  .sidebar-content::-webkit-scrollbar {
    width: 4px !important;
    margin-left: -10px !important;
  }
  
  /* Track */
  .sidebar-content::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px !important;
  }
  
  /* Handle */
  .sidebar-content::-webkit-scrollbar-thumb {
    background: white !important;
    border-radius: 10px !important;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .sidebar-content {
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
  }

  
  .sidebar-header h3 {
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .menu-open {
    background: #fff;
  }
  
  .nav-item:hover {
    color: #fff;
    background: #fff;
  }
  
  .items-menu {
    color: #fff;
    background: #0e6cff;
  }
  
  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
  }
  
  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
  
  .side-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    background: #0e6cff;
    border-radius: 3px;
  }
  
  .side-menu::-webkit-scrollbar-thumb:hover {
    background: #0e6cff;
  }

  .route-to-experience:hover{
    .sidebar-heading{
      color: #0086d9 !important;
  }
    cursor: pointer;
  }

  .route-to-infospot{
    margin-left: 20px;
    margin-top: -12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .sidebar-heading{
    font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  }

  .route-to-infospot:hover{
    font-weight: bold;
    color: #0086d9;
    text-decoration: underline;
    cursor: pointer;
  }