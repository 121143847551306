// src/components/modal.scss
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    // padding: 10px;
    border-radius: 0px;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    // border-radius: 10px;
    padding: 0px 5px;
    cursor: pointer;
  }
  