// src/pages/AboutUs.scss

.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.content-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 992px) {
    flex-direction: row;
    // margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.image-container {
  flex: 1;
  text-align: center;

  img {
    
    width: 960px;
    height: auto;

    @media (max-width: 1700px) {
      width: 840px;
    }

    @media (max-width: 1440px) {
      width: 720px;
    }

    @media (max-width: 1280px) {
      width: 640px;
    }

    @media (max-width: 1200px) {
      width: 600px;
    }

    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
}

.about-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;


  @media (max-width: 796px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
}

.about-tab {
  width: 50%;
}

.about-text-wrap {
  
    flex: 1;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-align: left;
    width: 100%;
  
    h1 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: left;
    }
  
    p {
      font-size: 19px;
      font-weight: 300;
      text-align: left;
    }
  
    b {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
    }

    @media (max-width: 1303px) {
      h1 {
        font-size: 24px;
        margin-bottom: 19px;
      }

      p {
        font-size: 18px;
      }
        b {
          font-size: 19px;
          font-weight: 600;
        }      
    }

    @media (max-width: 1252px) {
      h1 {
        font-size: 23px;
        margin-bottom: 19px;
      }

      p {
        font-size: 17px;
      }
        b {
          font-size: 18px;
          font-weight: 600;
        }      
    }

    @media (max-width: 1203px) {
      h1 {
        font-size: 22px;
        margin-bottom: 18px;
      }

      p {
        font-size: 16px;
      }
        b {
          font-size: 17px;
          font-weight: 600;
        }      
    }

    @media (max-width: 1154px) {
      h1 {
        font-size: 21px;
        margin-bottom: 18px;
      }

      p {
        font-size: 15px;
      }
        b {
          font-size: 16px;
          font-weight: 600;
        }      
    }
  
  
    @media (max-width: 1133px) {
      h1 {
        font-size: 23px;
        margin-bottom: 18px;
      }

      p {
        font-size: 14px;
      }
        b {
          font-size: 15px;
          font-weight: 600;
        }      
    }
  
    @media (max-width: 1067px) {
      h1 {
        font-size: 22px;
        margin-bottom: 17px;
      }

      p {
        font-size: 13px;
      }
        b {
          font-size: 14px;
          font-weight: 600;
        }  
    }
  
    @media (max-width: 1011px) {
      h1 {
        font-size: 21px;
        margin-bottom: 16px;
      }

      p {
        font-size: 12px;
      }
        b {
          font-size: 13px;
          font-weight: 600;
        }
    }

    @media (max-width: 967px) {
      h1 {
        font-size: 21px;
        margin-bottom: 16px;
      }

      p {
        font-size: 11px;
      }
        b {
          font-size: 12px;
          font-weight: 600;
        }
    }

    @media (max-width: 901px) {
      h1 {
        font-size: 20px;
        margin-bottom: 15px;
      }

      p {
        font-size: 10px;
      }
        b {
          font-size: 11px;
          font-weight: 600;
        }
    }

    @media (max-width: 852px) {
      h1 {
        font-size: 19px;
        margin-bottom: 14px;
      }

      p {
        font-size: 9px;
      }
        b {
          font-size: 10px;
          font-weight: 600;
        }
    }

    @media (max-width: 802px) {
      h1 {
        font-size: 18px;
        margin-bottom: 13px;
      }

      p {
        font-size: 9px;
      }
        b {
          font-size: 10px;
          font-weight: 600;
        }
    }

    @media (max-width: 802px) {
      h1 {
        font-size: 18px;
        margin-bottom: 13px;
      }

      p {
        font-size: 9px;
      }
        b {
          font-size: 9px;
          font-weight: 600;
        }
    }

    @media (max-width: 802px) {
      padding: 5px;
    }

    @media (max-width: 796px) {
      h1 {
        text-align: center;
      }

      p {
        font-size: 15px;
        text-align: center !important;
      }
        b {
          font-size: 15px;
          font-weight: 600;
        }
    }
}

.about-image-wrap {
  background-color: grey;

  img {
    width: 100%;
  }
}

.tex-container {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  text-align: left;
  width: 100%;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 250;
    // line-height: 40px;
    text-align: left;
  }

  b {
    font-size: 18px;
    font-weight: 600;
    // line-height: 40px;
    text-align: left;
  }

  @media (max-width: 1280px) {
    width: 100%;
    text-align: left;
  }

  @media (max-width: 992px) {
    padding: 10px;
    width: 100%;
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
    text-align: left; 
  }
}

.heading-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
  }
}

.main-content {
  flex: 1;
  flex-direction: row;
  margin-bottom: 80px;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    justify-content: center;
    display: flex;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.paragraph-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.download-wrapper {
  flex: 1;
  padding: 3% 7%;
  display: flex;
  flex-direction: column;
  text-align: left;
  
  p {
    font-size: 14px;
    font-weight: 200;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 10px;
    justify-content: center;
    width: 80%;
  }
}

.download-buttons {
  display: flex;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.mobile-container {
  flex: 1;
  position:relative
}

.image-wrapper img {
  width: 180px;
  position:absolute;
  top: -190px;
  right: 185px;

  @media (max-width: 900px) {
    width: 180px;
    right: 50px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.app-belt {
  background-color: #002440;
  margin-bottom: 5rem;
  margin-top: 10rem;

  @media (max-width: 768px) {
    padding: 2%;
  }
}

.pop-button {
  position: fixed !important;
  right: 0px;
  top: 110px;
  padding: 10px 20px;
  background: linear-gradient(to right, #47BFAB, #1C78BA);
  color: white;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.5;
  // z-index: 999 !important;

  @media (max-width: 690px) {
      top: 160px !important;
      padding: 5px 10px;
  }

  @media (max-width: 381px) {
    top: 120px !important;
    padding: 5px 10px;
}

  &:hover {
  opacity: 1;
    // transform: translateX(-100px); /* Moves the button to the left on hover */
    background-color: #2980b9; /* Darken the button color on hover */
  }
}

.playstore-div {
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 10px !important;
  }
}

.playstore-btn {
  max-width: 110px;
}

.appstore-btn {
  max-width: 110px;
}
