.tutorial-progress.progress {
  width: 212px;
  margin-bottom: 1.2rem;
  background: transparent;
  height: 0.5rem;
  border-radius: 0;
}

.tutorial-progress.progress .progress-bar {
  background-color: white;
  transition: none !important;
}

