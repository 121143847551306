@font-face{
  font-family: 'Helvetica Neue LT Pro 45 Light';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 65 Medium';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 75 Bold';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 55 Roman';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
}





.special-modal-solution {
  background-color: transparent !important;
  opacity: 1;



  .productImage {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

.minHeight-200{
  min-height: 230px !important;
}

.minHeight-180{
  min-height: 180px !important;
}


.minHeight-200-row-1{
  min-height: 200px !important;
}

.minHeight-300{
  min-height: 300px !important;
  max-height: 300px !important;
}


.minHeight-266{
  min-height: 266px !important;
  max-height: 266px !important;
}




.minHeight-300-row-1{
  min-height: 100px !important;
}


 
  .modal-content {
    border-radius: 0px;
    border: none !important;
    // overflow: hidden;
  }
  .modal-body {
    padding: 0rem;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }


@media(max-width:1600px) and (min-width:1200px){
  .modal-dialog{
    max-width: 850px !important;
  }
}

@media (max-width:1200px) and (min-width:990px) {
  .modal-dialog{
    max-width: 90% !important;
  }
}



  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 0px;
    padding-left: 0px;
  }
  .color-gray {
    color: #acacac !important;
  }

  .hr {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  @media(max-width:990px){
    .hr {
      display: none !important;
    }
  }

  .closes {
    // margin-top: -7px;
    // opacity: 0.5 !important;
    background-color: transparent;
    border: none;
  }

  .closes:hover{
    opacity: 0.3 !important;
  }



    .saleIcon {
      
      width: 56px;
      margin: -11px 10px 0px 0px;
      float: left;
    }


  
  // @media(min-width:1600px){
  //   .saleIcon {
  //     margin: -10px 10px 00px 0px !important;
  //   }
  // }
  

  .labels {
  font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
  }

  .infospot-title{
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .bottom-label{
    font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
    font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
  }

  
  @media (max-width:768px) {
    .title {
    padding-left: 22%;
    }
  }

  .body-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px !important;
    line-height: 1.63;
    color: #5d5d5d;
    margin: 15px 0px;
    margin-right: 1rem;
  }
  .color-blue {
    color: #00359f;
  }
  .btn-custom {
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0px;
    border-color: #00359f;
    font-family: Arial;
    border-radius: 4px;
    min-width: 87%;
    padding-top: 12px;
    padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00359f;
    position: absolute;
    bottom: 15px;
  }

  .border-blue{
    margin-left: -2px;
    outline: 2px solid #00359f;
    outline-offset: -3px;
  }

  .btn-custom:hover {
    color: #fff;
    background-color: #00359f;
    border-color: #00359f;
  }


.btn-custom-get-in-touch{
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0px;
  color: #fff;
  border-radius: 4px;
  background-color: #fff;
  color:#00359f;
  font-family: Arial;
  min-width: 87%;
  padding-top: 12px;
  padding-bottom: 12px;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
  position: absolute;
  bottom: 15px;
}
  

.btn-custom-get-in-touch:hover{
  color: #fff;
    background-color: #00359f;
    border-color: #fff;
}


  .hr-bottom-row{
    width: 40px;
    height: 2px;
    background-color: #ffffff;
  }

  .m-lr-13 {
    margin-left: 1.3rem !important;
    margin-right: 1.3rem !important;
  }
  .bg-blue {
    background-color: #00359f;
  }
  .b-right {
    border-right: 1px solid #ffffff73;
  }
  .color-white {
    color: white;
  }
  .font-16{
    font-size: 16px;
  }
  .font-14{
    font-size: 14px;
  }
  .pull-right {
    float: right;
  }
  .svg-arrows-container svg {
    opacity: 0.7;
  }
  .svg-arrows-container svg:hover {
    opacity: 1;
  }
  .bg-yellow {
    background-color: #f0b323;
  }
  .color-yellow {
    color: #f0b323;
  }
  .w-100 {
    width: 100%;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-200 {
    font-weight: 200;
  }




 

 



  .breadcrumb-text{
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
  }


  .z-index-1{
    z-index: -999;
  }
  .z-index-0{
    z-index: 0;
  }
  .white-background{
    background-color: white;
  }
  .bread-crumb{
  letter-spacing: 1px !important;
  min-height: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  }

  .carousal-title{
    font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .cta{
    cursor: pointer;
    svg{
      opacity:0.65;
    }
  }

  .cta:hover{
    .cta-text{
    color: #fff;
  }
  svg{
    opacity:1;
  }
 
  }

  .cta-text{
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.43px;
    color: rgba(255, 255, 255, 0.65);
    font-family: 'Open Sans', sans-serif;
    margin-right:5px;
  }






  .carousal{
    overflow-y: auto;
    height: 209px;
    padding-right: 40px;
  }

  .carousal-third{
    overflow-y: auto;
    height: 150px;
    padding-right: 40px;
  }

  .not-scroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .not-scroll::-webkit-scrollbar {
    display: none;
  }



  /* width */
  .carousal::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
.carousal::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px !important;
}

/* Handle */
.carousal::-webkit-scrollbar-thumb {
  background: white !important;
  border-radius: 10px !important;
}


.carousal-third::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
.carousal-third::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px !important;
}

/* Handle */
.carousal-third::-webkit-scrollbar-thumb {
  background: white !important;
  border-radius: 10px !important;
}

  .carousal-item{
    // scroll-snap-align: start;
  }

  .border-white-special-case{
    border: 1px solid white;
  }
}


.closes img {
  max-width: 200px;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center'
}

@media (max-width: 768px) {
  .closes img {
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .closes img {
    max-width: 125px;
  }
}
