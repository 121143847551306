@font-face{
    font-family: 'Helvetica Neue LT Pro 45 Light';
    src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 65 Medium';
    src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 75 Bold';
    src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 55 Roman';
    src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
  }


 /* width */

 .results::-webkit-scrollbar {
    position: fixed !important;
    right: 10px;
    width: 4px !important;
  }
  
  /* Track */
  .results::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px !important;
  }
  
  /* Handle */
  .results::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: white !important;
    border-radius: 10px !important;
  }

  
 .filter-results::-webkit-scrollbar {
    display: none;
  }




.filter-results{
position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  color: white;
  padding-bottom: 30px;
  overflow-y: scroll;



.header{
    text-align: center;
}

.top-part{
    position:fixed;
    top:40px;
    z-index: -1;
    height:140px;
    width: 100%;
}

.cursor-pointer{
    cursor: pointer;
}

.close-icon{
    z-index: 200;
    float: right;
}

.container-div{
    padding-top: 40px;
    .results-heading{
        font-family: 'Helvetica Neue LT Pro 55 Roman';
          font-size: 28px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
        }
    .results-subheading{
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }
    .selected-items{
        margin-bottom: 10px;
        margin-left: 8px;
    border-radius: 25.5px;
  background-color: #ffffff;
  padding: 16px 16px 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;

    }
}


.results{
    overflow-y: auto;
    position: fixed;
    top: 160px;
    bottom: 10px;
    width: 99.5%;
    margin-top: 40px;
    .applications{
        padding: 10px;
        .applications-heading{
            margin-left:-12px;
            margin-bottom: 8px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.65);
        }
            .card-container{
                justify-content: center !important;
            } 
        
        .application-card{
            margin: 0px 11.5px 23px 0px;
            padding: 8px 8px 12px;
            background-color: #ffffff;
            width: 266px;
            height: 183px;
            text-align: center;
            .card-pic{
                width: 250px;
                height: 136px;
            }
            .card-text{
                font-family: 'Helvetica Neue LT Pro 55 Roman';
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #282828;
                margin-top: 14px;
            }
        }
    }


    .products{
        padding: 10px;
        .products-heading{
            margin-left:-12px;
            margin-bottom: 8px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.65);
        }
        @media (max-width:768px) {
            .card-container{
                justify-content: center !important;
            } 
        }
        
        .products-card{
            margin: 0px 11.5px 23px 0px;
            padding: 8px 8px 12px;
            background-color: #ffffff;
            width: 210px;
            height: 135px;
            text-align: center;
            .card-tagline{
                width: 194px;
                height: 61px;
                padding: 9px 8px 10px;
                background-color: #0086d9;
                color: #fff;
                font-family: 'Helvetica Neue LT Pro 55 Roman';
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
            }
            .card-title{
                margin-top: 16px;
                font-family: 'Helvetica Neue LT Pro 55 Roman';
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #282828;
            }
        }
    }

}



}