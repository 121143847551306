@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


.mouse-click-wrap {
  position: relative;
  /* height: 300px; */
}

.mouse-click {
  position: absolute;
  top: 0px;
  left: 0;
  width: 108px;
  height: 180px;
  overflow: hidden
}


.loader-wrap {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.tutorial-loader-items {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filled-loader {
  background-color: #80E7FF
}

.info-txt {
  text-align: center;
  max-width: 90%;
  font-family: 'Open Sans';
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.font-weight-bold{
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.location-img {
  width: 71px;
}
