.welcome-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(0px);
  z-index: 100;
  color: white;
  padding-bottom: 30px;
  padding-top: 80px;
  overflow-y: hidden;
}

.weclome-overlay-control {
  backdrop-filter: blur(0px);
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 1024px) {
  .welcome-overlay {
    padding-top: 140px;
  }
}

.modal-overlay{
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
}


.fadeIn-welcome{
  animation: fadein-welcome 0.5s infinite;
  animation-iteration-count: 1 !important;
}

@keyframes fadein-welcome{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.fadeOut-welcome{
  animation: fadeOut-welcome 0.3s infinite;
  animation-iteration-count: 1 !important; 
  opacity: 0;
  pointer-events: none;
}

@keyframes fadeOut-welcome{
  0%{opacity: 1;}
  100%{opacity: 0;}
}
