/* src/components/style.css */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-progress {
  width: 100%;
  margin-top: 10px;
}
