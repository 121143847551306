.silicon-lab-logo {
  position: absolute;
  top: 15px;
  left: 40px;
  max-width: 100px;
}

@media(max-width:600px){
  .silicon-lab-logo{
    width: 70px;
    top: 25px;
    left: 20px;
  }
}

.silicon-lab-anchor{
z-index: 41;
    position: absolute;
    top: 0px;
}

.sidebar-toggle-button{
  position:fixed;
  top:20px;
  left:20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index:31;
  color: white !important;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
}

.productImage {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  max-width: 800px; /* Adjust this based on your design */
  margin: 0 auto;
}










