// // src/components/Navbar.scss
// .navbar {
//     width: 100%;
//     background-color: #f8f9fa;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: fixed;
//     z-index: 1;
//     padding: 0%;
//   }
  
//   .logo {
//     height: 50px;
//     width: auto;
//     margin-top: 15px;
//   }
.navbar {
  width: 100%;
  background-color: #f8f9fa;
  display: flex;
  justify-content: right;
  align-items: right;
  position: fixed;
  z-index: 1;
  padding: 0%;
}

.logo {
  height: 50px;
  width: auto;
  margin-top: 15px;
  cursor: pointer;
}

.logo-responsiveness {
    display: flex;
    justify-content: center;
    width: 100%;
}

.dropdown {
  position: absolute;

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;

    .line {
      background: linear-gradient(to right, #47BFAB, #1C78BA);
      height: 3px;
      border-radius: 2px;
      transition: opacity 0.3s;
      opacity: 0.8;
    }

    &:hover .line {
      opacity: 1;
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 220%;
    margin-left: -130px;
    background: linear-gradient(to right, #47BFAB, #1C78BA);
    height: auto;
    display: block;
    border-radius: 0px;
    border: 0px;

    .dropdown-item {
      color: white;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      opacity: 0.8;

      &:hover,
      &:focus,
      &:active  {
        background-color: transparent;
        color: white;
        text-decoration: underline;
        opacity: 1;
      }
    }

    .menu-logo {
      display: flex;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      width: 15px;
      margin: auto;
  }
  }

  .menu-divider {
    height: 1px; /* Thickness of the divider */
    background-color: #ccc; /* Color of the divider */
    margin: 0px 0; /* Space around the divider */
    opacity: 0.3;
  }

  /* Alternatively, using a pseudo-element for the divider */
  .menu-divider::before {
    content: '';
    display: block;
    height: 1px;
    background-color: #ccc;
    margin: 2px 0;
  }

  .footer{
    background-color: inherit;
    text-align: center;
    font-size: xx-small;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
