@font-face{
    font-family: 'Helvetica Neue LT Pro 45 Light';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 65 Medium';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 75 Bold';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
  }
  @font-face{
    font-family: 'Helvetica Neue LT Pro 55 Roman';
    src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
  }
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.SIL_Logo-success{
    object-fit: contain;
    position: fixed;
    top: 52px;
}

.form-success-root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.success-icon{
    width: 72px;
    height: 72px;
    object-fit: contain;
}

.Thank-you {
    max-width: 183px;
    height: 43px;
margin-top: 16px;
    font-family: 'Helvetica Neue LT Pro 65 Medium';
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }


  .Weve-successfully-r {
    margin: 8px 0 0;
    max-width: 900px; 
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .text-container{
    max-width: 90vw !important; 
  }

  

  
  .form-status-submit{
    width: 267px;
    height: 49px;
    margin: 40px 242px 0 244px;
    border: none;
    border-radius: 4px;
    background-color: #0086d9;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .form-status-submit:hover{
    border: 2px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .button-div{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}