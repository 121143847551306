// src/components/ImageSlider.scss

.slider-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .slider-image {
    width: 90%;
    // max-width: 300px;
    height: auto;
    border-radius: 8px;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slick-dots li button:before {
    font-size: 16px;
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #70C7B1;
  }
  
  .slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li {
    margin: 0 5px;
  }
  
  // src/components/style.scss

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
  &:before {
    font-size: 40px;
    color: #65c8d0;  
    @media (max-width: 796px) {
      display: none;
    }
  }
}

.slick_prev {
  left: -45px;
}

.slick_next {
  right: -45px;
}