@font-face{
  font-family: 'Helvetica Neue LT Pro 45 Light';
  src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 65 Medium';
  src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 75 Bold';
  src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 55 Roman';
  src:url('../../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

/* Hide scrollbar for Chrome, Safari and Opera */



.fadeIn-use-case{
animation:fade-in 0.5s;
animation-iteration-count: 1;
}

@keyframes fade-in{
  0%{transform: scale(1);}
  50%{transform: scale(1.1);}
  100%{transform: scale(1);}
}



.hover-text{
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  left: 55%;
  margin-top: -10px;
  overflow: hidden;
  animation: hover-text .4s ease-in-out;
}

@keyframes hover-text{
  0%{height: 0%;}
  100%{height: 20%;}
}


.SIL_Logo-intro{
  width: 159px;
  height: 78px;
  object-fit: contain;
  align-self: center;
}

@media (max-width:576px) {
  .intro-root{
    margin-top: 800px !important;
  }
}

@media (max-width:430px) {
  .intro-root{
    margin-top: 1200px !important;
  }
}



.Modernize-Your-Retai {
  max-width: 560px;
  margin-top: 46px;
  font-family:'Helvetica Neue LT Pro 65 Medium';
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left !important;
}

.Technology-is-changi {
  max-width: 540px;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.text-container-intro{
  max-width: 90vw !important; 
}

.explore-button {
  width: 320px;
  height: 49px;
  margin-top: 40px;
  padding: 15px 10px 20px 10px;
  border: none;
  border-radius: 4px;
  background-color: #0086d9;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.explore-button:hover{
  padding-top: 13px !important;
   border: 2px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.4);
}

.Explore-by-Use-Case {
  /* width: 349px;
  height: 28px; */
  margin: 0 5px 0px 13px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.content-col{
  margin-top: 110px;
  display: flex;
  flex-direction: column;
}

.explore-col{
  padding-left: 93px !important;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}


.custom-containers {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 940px) {
  .explore-col{
    border: none;
  }
  .custom-containers {
    width: 100%;
    margin: 0 auto;
    display: block !important;
  }
}
@media (max-width:940px) and (min-width:800px) {
  .SIL_Logo-intro{
    margin-top: 1300px;
  }
}

@media (max-width:800px) and (min-width:576px) {
  .SIL_Logo-intro{
    margin-top: 1800px;
  }
}


@media (min-width: 992px) {
  .custom-containers {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .custom-containers {
    width: 1200px;
  }
}

.section-txt {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  margin-top: 50px;
}

.section-txt:hover{
  color: #0086d9;
  cursor: pointer;
}


.hover-canvas:hover{
  .section-txt{
    font-weight: bold;
    position: relative;
  }
}

.sub-text{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px;
  z-index: 100;
}

.sub-text:hover{
  color: #0086d9;
  cursor: pointer;
  font-weight: bold;
}

.section-img {
  margin-top: 64px;
  width: 165px;
  cursor: pointer;
}

.intro-root{
  margin-top: -100px;
}




