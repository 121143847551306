// src/components/Footer.scss

.footer {
    background-color: #2C2C2C;
    color: #fff;
    padding: 40px 0 0px;
    text-align: center;
    width: 100%;
    // height: 900px;
    // margin-bottom: 0px;
    padding-bottom: 10px;
  
    .footer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 40px;
      }
    }
  
    .footer-logo-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
      }
  
      .footer-logo {
        max-width: 40px;
        margin-bottom: 10px;
  
        @media (min-width: 768px) {
          margin-bottom: -20px;
          margin-right: 20px;
        }
      }
  
      .contact-info {
        text-align: left;
  
        p {
          margin: 5px 0;
  
          a {
            color: #fff;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  
    .footer-social-section {
      h4 {
        margin-bottom: 10px;
      }
  
      .social-icons {
        display: flex;
        justify-content: center;
  
        @media (min-width: 768px) {
          justify-content: flex-start;
        }
  
        .social-icon {
          color: #fff;
          font-size: 24px;
          margin: 0 10px;
          transition: color 0.3s;
  
          &:hover {
            color: #ccc;
          }
        }
      }
    }

    .footer-copyright{
        p {
            text-align: center;
            margin-top: 20px;
            // padding-right: 40px;
            padding-bottom: 0px;
            padding-top: 10px;
            // text-decoration: wavy;
            font-weight: 100;
            font-size: smaller;
        }
    }
    
    .company-color {
        color: white;
        font-weight: 400;
    }
}
  