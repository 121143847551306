
@font-face{
  font-family: 'Helvetica Neue LT Pro 45 Light';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 65 Medium';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 75 Bold';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 55 Roman';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
}

.mini-map-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 40;
  color: white;
}

.location-icon {
  z-index: 45;
  width: 220px;
  cursor: pointer; 
}

.cross-icon {
  z-index: 45;
  cursor: pointer; 
}

@media(max-width:600px){
  .location-icon{
  width: 100px;
}
}

.mini-map-modal {
  background-color: rgb(0 0 0 / 49%);
  border-radius: 1px;
  position: fixed;
  top: 70px;
  right: 70px;
  z-index: 2;
}

.map-modal{
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  -webkit-backdrop-filter: blur(50px);
  /* backdrop-filter: blur(50px); */
  /* background-color: rgba(0, 0, 0, 0.7); */
}




.text{
  color: white;
  position: absolute;
  font-size: 19px;
  top: 50%;
  left: 50%;
  margin-top: 350px;
  margin-left: -250px;
  font-family: 'Open Sans'
}

.fadeOut{
  opacity:0;
  transition: opacity 1s;
  pointer-events: none;

}
.fadeIn{
  pointer-events: all;
  opacity:1;
  transition:opacity 1s;
}
