@import url("https://rsms.me/inter/inter.css");
@font-face{
  font-family: 'Helvetica Neue LT Pro 45 Light';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Lt.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 65 Medium';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Md.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 75 Bold';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Bd.otf')
}
@font-face{
  font-family: 'Helvetica Neue LT Pro 55 Roman';
  src:url('../../assets/Helvetica\ Neue/HelveticaNeueLTPro-Roman.otf')
}

.map-heading{
  font-family: "Helvetica Neue LT Pro 65 Medium";
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
}

.map-tagline{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-top: -15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}


.containerHover{
  padding: 10px 10px 10px 16px;
  width: 254px;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.65);
  text-align: center;
  display: none;
}

.map-head-text{
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
}

.map-head-text:hover{
  font-weight: bold;
  color: #0086d9;
}

.map-sub-text{
  margin-top: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828
}

.map-sub-text:hover{
  font-weight: bold;
  color: #0086d9;
}


.containerHover2{
  padding: 10px 10px 10px 16px;
  width: 400px;
  border-radius: 25px;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.65);
  text-align: center;
  display: none;
}

.containerPin1{
  display: block;
  pointer-events: none;
  z-index: 0;
  margin-left:100px !important;
  margin-top:50px ;
}


.containerPin2{
  display: block;
  pointer-events: none;
  z-index: 0;
  margin-top: -100px !important;
  margin-left:-230px !important;
}

.containerPin3{
  display: block;
  pointer-events: none;
  transform: translate3d(-100%, -110%, 0);
}

.containerPin4{
  display: block;
  pointer-events: none;
  z-index: 0;
    transform: translate3d(-120%, -40%, 0);
}

.containerPin5{
  display: block;
  pointer-events: none;
  z-index: 0;
  transform: translate3d(32%, -117%, 0);
}

@media (max-width:900px) {
  .containerPin1{
    transform: translate3d(-80%, 0%, 0) !important;
  }
  .containerPin4{
    transform: translate3d(-60%, -10%, 0) !important;
  }
  .containerPin2{
    transform: translate3d(40%, 0%, 0) !important;
  }
  .containerPin3{
    transform: translate3d(-40%, 0%, 0) !important;
  }
}




.map-text{
  padding: 10px;
 text-align: center;
 height: 0px;
}