 
  .main-container {
    overflow-y: auto;
  }
  
  .text-container {
    flex: 1;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the text vertically */
    align-items: center; /* Centers the text horizontally */
    width: 80%;
  
    h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }

    h5 {
        // font-size: 2.5rem;
        // margin-bottom: 20px;
        text-align: left;
    }
  
    p {
        font-size: 1rem;
        line-height: 1.6;
        text-align: justify;
        font-weight: 300;
    }
  
    @media (max-width: 768px) {
      padding: 10px;

      h6 {
        text-align: left;
      }
    }

    @media (max-width: 620px) {

      h1 {
        font-size: 1.3rem;
      }
    }
  }
