.special-modal-product {
  img.productImage {
    width: inherit;
  }
  .modal-content {
    border-radius: 0px;
  }
  .modal-body {
    padding: 0rem;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 0px;
    padding-left: 0px;
  }
  .color-grey {
    padding-top: 10px;
    color: #acacac !important;
    margin-bottom: 8px;
  }
  .font-size-10 {
    font-size: 10px;
  }
  .margin-style{
    margin: 12px;
  }
  .hr {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 12px;
    margin-right: 12px;
  }
  .close {
    margin: 0rem 0.35rem;
    font-weight: 400;
    opacity: 1;
  }
  .label {
    font-weight: 300;
  }
  .body-text {
    font-weight: 300;
    font-size: 16px !important;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #3f3f3f;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 15px 0px;
    margin-right: 1rem;
    opacity: 1 !important;
  }
  .color-blue {
    color: #00359f;
  }
  .color-gray {
    color: #828282 !important;
  }

  .m-lr-13 {
    margin-left: 1.4rem !important;
    margin-right: 1.4rem !important;
  }
  .bg-blue {
    background-color: #00359f;
  }
  .b-right {
    border-right: 1px solid #fff;
  }
  .b-right-black {
    border-right: 1px solid #0000001f;
    
  }
  .color-white {
    color: white;
  }
  .pull-right {
    float: right;
  }
  .pull-left{
    float: left;
  }
  .svg-arrows-container svg {
    opacity: 0.7;
  }
  .svg-arrows-container svg:hover {
    opacity: 1;
  }
  .bg-yellow {
    background-color: #f0b323;
  }
  .color-yellow {
    color: #f0b323;
  }
  .w-100 {
    width: 100%;
  }
  .pl-20{
    padding-top: 5px;
    padding-left: 20px;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-200 {
    font-weight: 200;
  }
  .watch-now{
    text-decoration: none;
    transition: 0.3s;
    font-size: 14px;

    svg{
      opacity: 0.65;
    }
  }

  @media (min-width:768px) {
    .watch-now{
      position: absolute;
      bottom: 20px;
    }
  }

  .watch-now:hover{
    cursor: pointer;
    color: #000;
  }

  .close {
    margin: 0rem 0.6rem;
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
  }

  .close.back-close {
    margin-top: 2px;
  }

  
  .closes-product {
    opacity: 0.5 !important;
    background-color: transparent;
    width: 24px;
  }

  .closes-product:hover{
    opacity: 0.3 !important;
  }

  .back-cross {
    margin-top: 0;
    margin-bottom: 0.2rem;
  }

  .breadcrumb-text{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888888;
    display: inline-block;
    transform: translateY(7px) !important;
  }

  @media (max-width:768px) {
    .breadcrumb-text{
    max-width: 250px;
    }
  }

  .font-20{
    font-size: 20px;
  }
  .font-16{
    font-size: 16px;
  }
  .font-14{
    font-size: 14px;
  }
  .product-title{
    font-family: 'Helvetica Neue LT Pro 65 Medium' !important;
    font-size: 18px;
    line-height: 25px;
  }
  .product-description{
    font-family: 'Open Sans', sans-serif;
  font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #282828;
  }
  .key-features-heading{
    margin-bottom: 0px !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #282828;
    letter-spacing: 1px !important;
  }
  .video-title{
    font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
    color:#fff;
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 1px !important;

  }

  .material-type{
    font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px !important;
    margin-bottom: 2px !important;
  }

  .bread-crumb{
    font-family: 'Helvetica Neue LT Pro 45 Light' !important;
    letter-spacing: 1px !important;
    .color-grey{
      padding-top:0px !important;
    }
  }

  .margin-4{
    margin: 5px 12px 0;
  }

  .product-link{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
    font-size: 14px !important;
  }

  @media (min-width:768px) {
    .product-link{
    position: absolute;
    bottom: 0px;
    }
  }
}

.product-tagline{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
    p{
      padding-top: 8px;
      margin-top: 14px;
      text-align: center;
      width: 85%;
    }
  background-color: #00359f;
  font-family: 'Helvetica Neue LT Pro 55 Roman' !important;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.product-cta{
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: rgba(255, 255, 255, 0.65);
}


.watch-now:hover{
  .product-cta{
  color: #fff;
}
svg{
  opacity: 1;
}
}

.bg-gray{
  padding-bottom: 10px;
  background-color: #999999;
}
