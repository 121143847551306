.menu-bar {
  position: fixed;
  top: 90px;
  right: -100px; /* Hidden by default */
  width: 100px;
  height: 180px;
  background: linear-gradient(to right, #47BFAB, #1C78BA);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
//   border-top-left-radius: 30px;
//   border-bottom-left-radius: 30px;
//   opacity: 0.5;

  &.open {
    right: 0; /* Slide in from the right */
  }

  .close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }

    .menu-logo {
        display: flex;
        // margin-left: 20px;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        width: 15px;
        margin: auto;
    }

    .menu-divider {
        height: 1px; /* Thickness of the divider */
        background-color: #ccc; /* Color of the divider */
        margin: 0px 0; /* Space around the divider */
        opacity: 0.3;
      }
    
      /* Alternatively, using a pseudo-element for the divider */
      .menu-divider::before {
        content: '';
        display: block;
        height: 1px;
        background-color: #ccc;
        margin: 2px 0;
    }

    .dropdown-item {
        color: white;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        opacity: 0.8;
        // font-size: ;
  
        &:hover,
        &:focus,
        &:active  {
          background-color: transparent;
          color: white;
          text-decoration: underline;
          opacity: 1;
        }
      }
}
