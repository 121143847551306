svg {
  cursor: pointer;
  z-index: 40;
}

svg:hover{
  .orange-fill{
  fill: #F0B323 !important;
}
}

.location-icon{
  width: 218px;
  margin-top: 24px;
  margin-right: 40px;
}

