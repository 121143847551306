.action-bar-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 40;
  display: flex;
  width: 100vw;
  height: 80px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.7);
}

.action-icon {
  width: 40px;
  margin: 5px 9px -3px;
  object-fit: contain;

  @media (max-width: 600px) {
    display: flex;
    flex: 1;
    width: 25px;
    margin: 15px 9px -3px;
  }
}


.cursor-pointer{
  cursor: pointer;
}

.action-text{
  margin: 6px 0 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.actions-wrap {
  position: fixed;
  right: -25px;
  display: flex;
  justify-content: space-between;
  padding: 5px 40px;
}

.share-toggler{
  position: absolute;
  top: 5px;
  padding-right: 20px;
}

.share-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 130px;
  .action-icon{
    margin-top: 10px;
  }
  overflow: hidden;
  margin-top: -145px !important;
  margin-bottom: 70px !important;
}

.hidden-wrap{
  display: block;
  pointer-events: none; 
  opacity: 0;
  margin-top: -20px;
}

.share-fadeIn{
  display: block;
  pointer-events: all;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 32px 32px 0px 0px;
  padding:10px 10px 0px 10px;
  margin-top: 130px;
-webkit-animation: fadein 0.7s forwards;
    -webkit-animation-delay: 2s;
    animation: fadein 0.7s forwards;
    
}

@-webkit-keyframes fadein {
  100% {display: block; pointer-events: all;margin-top: 0px;}
}

@keyframes fadein {
  100% {display: block; pointer-events: all;margin-top: 0px;}
}

.share-fadeOut{
  display: block;
  pointer-events: all;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 32px 32px 0px 0px;
  padding:10px 10px 0px 10px;
  margin-top: 0px;
-webkit-animation: fadeout 0.7s forwards;
    -webkit-animation-delay: 2s;
    animation: fadeout 0.7s forwards;
}

@-webkit-keyframes fadeout {
  100% {display: none; pointer-events: none;margin-top: 130px; }
}

@keyframes fadeout {
  100% {display: none; pointer-events: none;margin-top: 130px;}
}




.share-wrap img {
  cursor: pointer;
}

.links-wrap {
  width: 538px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  padding-left: 20px;
}

.links-wrap a {
  color: white;
  cursor: pointer;
}

.filter{
  margin-right: 25px;
  padding-right: 15px;
  border-right: 1px solid #fff;
}

.filter:hover{
  .action-icon{
    filter: brightness(0) invert(61%) sepia(75%) saturate(6601%) hue-rotate(181deg) brightness(93%) contrast(101%);
  }
  .action-text{
  color: #0086d9 !important;
}
  
}

#lightbox-contact-form {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0,0,0,.7);
  overflow-y: scroll;
}

#lightbox-contact-form-inner-wrapper {
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translate(-50%);
  width: 90vw;
  max-width: 420px;
  border-radius: 0px;
  padding: 20px;
  box-sizing: border-box;
  padding: 20px 20px 120px;

  label.mktoLabel.mktoHasWidth {
    display: block !important;
    margin-top: 7px;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: normal;
    color: #fff;
  }

  label#LblMarketing_Opt_In__c {
    display: none !important;
  }

  label#LblmktoCheckbox_1195_0 {
    width: 50vw;
    max-width: 700px;
    color: #fff;
    span {
      color: #fff !important;
    }
    a{
      font-weight: bold;
      color: #F0B323 !important;
    }
  }

  input#LblmktoCheckbox_1195_0 {
    color: #fff;
  }
}

#close-lightbox-contact-form {
  position: sticky;
  top: 0;
  left: 100%;
  color: #fff;
  opacity: .6;
  cursor: pointer;
  font-size: 48px;
  line-height: 30px;
  font-weight: 300;
  transform: rotate(45deg) translate(-20px, 30px);
  font-family: "Open Sans",sans-serif;
  transition: all .15s linear;
  width: 30px;
  height: 30px;
  text-align: center;
}

#lightbox-contact-form-background-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

#lightbox-contact-form-background-layer:hover + #close-lightbox-contact-form,
#close-lightbox-contact-form:hover {
  opacity: 1;
}

svg.contact-us rect {
  stroke: #000;
}

.mktoForm div.mktoTemplateBox {
  color: #fff;
}

body .mktoForm .mktoCheckboxList > label {
  color: #fff;
  span {
    color: #fff !important;
  }
}

svg.contact-us .orange-fill {
  stroke: #fff;
  fill: #fff;
}

svg.contact-us:hover .orange-fill {
  stroke: #F0B323 !important;
}

.mktoField,
.mktoForm,
.mktoForm div,
.mktoForm span,
.mktoForm label,
.mktoForm p {
  font-family: 'Helvetica Neue LT Pro 45 Light' !important;
}

.mktoField {
  height: auto !important;
}

.mktoForm input.mktoField[type="text"],
.mktoForm input.mktoField[type="url"],
.mktoForm input.mktoField[type="email"],
.mktoForm input.mktoField[type="tel"],
.mktoForm input.mktoField[type="number"],
.mktoForm input.mktoField[type="date"] {
  padding: 10px !important;
  height: 50px;
  border-radius: 5px !important;
  background-color: #f7f7f7;
  border: none !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  line-height: 17.4px;
}

.mktoForm input.mktoField[type="text"]:focus,
.mktoForm input.mktoField[type="url"]:focus,
.mktoForm input.mktoField[type="email"]:focus,
.mktoForm input.mktoField[type="tel"]:focus,
.mktoForm input.mktoField[type="number"]:focus,
.mktoForm input.mktoField[type="date"]:focus {
  box-shadow: 0 0 5px #f7f7f7;
}

.mktoForm select.mktoField,
.mktoForm input.mktoField[type="text"]#PostalCode {
  appearance: none;
  background-image: none;
  padding: 13px 15px 9px !important;
  border-radius: 4px !important;
  border: 1px solid #f7f7f7 !important;
  font-size: 14px !important;
  font-family: 'Open Sans' !important;
  background-color: #f7f7f7;
}

.mktoForm select.mktoField:focus,
.mktoForm input.mktoField[type="text"]#PostalCode:focus {
  border: 1px solid #f7f7f7;
  box-shadow: 0 0 5px #f7f7f7;
}

body .mktoForm .mktoCheckboxList > label {
  margin-left: 30px !important;
}

.mktoCheckboxList input + label:before {
  position: absolute;
  transform: translate(-30px, 1px);
  font-size: 9px !important;
  line-height: 9px !important;
  width: 17px !important;
  height: 17px !important;
}

// .mktoCheckboxList input + label:checked {
// background-color: #00ffa7 !important;
// }

.mktoForm .mktoButtonRow .mktoButtonWrap.mktoSimple .mktoButton {
  width: 267px;
  height: 49px;
  border-radius: 4px !important;
    background-color: #0086d9 !important;
    font-family: Arial !important;
    font-size: 14px !important;
    font-weight: bold !important;
  font-weight: 700 !important;
  color: #fff !important;
  text-align: center;
  background-image: none !important;
  text-transform: uppercase;
  transition: .2s all ease !important;

  &:hover {
    border: 2px solid #ffffff !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
  }
}

.mktoForm .mktoButtonRow {
  margin: auto !important;
  display: block !important;
}

.mktoForm .mktoError .mktoErrorMsg {
  padding: 10px 8px 4px !important;
}

.mktoForm .mktoFormCol,
.mktoForm .mktoFieldWrap,
.mktoForm .mktoField {
  width: 100% !important;
}

.mktoForm.mktoLayoutAbove .mktoCheckboxList {
  width: 100% !important;
}

.SILI_Logo{
  margin:auto;
  width: 159px !important;
  height: 78px !important;
}


@media (max-height:757px) {
  .filter-modal{
    max-height: 500px;
    min-height: 500px;   
  }
  .filter-body{
    overflow: auto;
    height: 380px;
  }
  .filter-body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .filter-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}


.filter-modal{
  position: absolute;
  bottom: 85px !important;
  border-radius: 16px 16px 0px 0px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.65);
  width:250px !important;
  height: 670px;
  margin-left: -100px;

  .filter-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 7px;

  .filter-heading{
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  }

  .filter-reset{
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0086d9;
    margin-top: 10px;
  }
  }

.filter-body{
  .toggler{
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
    background-color: rgba(255, 255, 255, 0.13);
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    .sign{
      font-size: 16px !important;
    }
  }
  .list-item{
    .item-sub{
    padding: 16px 17px 13px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
    .item-checkbox{
      cursor: pointer;
      input{
        cursor: pointer;
        position: relative;
        left: 21px;
        top: 5px;
        width: 19px;
        height: 19px;
        opacity: 0 !important;
      }
    }
  }
    padding: 0px 0px 0px 16px;
  }
}
.show-results{
  position: absolute;
  margin-left: -110px;
  bottom: 10px;
  padding: 12px 53px;
  border-radius: 4px;
  background-color: #0086d9;
  color: #fff;
  text-align: center;
  // width: 100%;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  border-radius: 4px;
}

}

.actions-wrap .info-text {
  color: white;
  &:hover {
    text-decoration-line: none;
  }
}